<template>
    <div class="list">
        <!-- 遮罩层 -->
        <div class="mask" v-if="show_sort || show_screen" @click="hideMask" @touchmove.stop.prevent="nomove"></div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list v-model="loading" :finished="finished" @load="onLoad(page)">
                <div class="top">
                    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" v-if="imgList.length">
                        <van-swipe-item v-for="(item, index) in imgList" :key="index"><img :src="item" @click="lookImg(index)" /></van-swipe-item>
                        <!-- <template #indicator>
                            <div class="custom-indicator">{{ index + 1 }}/4</div>
                        </template> -->
                    </van-swipe>
                    <div class="Tips">
                        <div><span class="iconfont icon-zhaoshang"></span> 招工岗位严格审核</div>
                        <div><span class="iconfont icon-xinxi"></span>招工信息真实有效</div>
                        <div><span class="iconfont icon-kefu"></span>在线客服及时反馈</div>
                    </div>
                </div>
                <div class="middle">
                    <div>
                        <img src="@/assets/img/recruitment/recruitment1.png" alt="">
                        <div>热门岗位</div>
                    </div>
                    <div>
                        <img src="@/assets/img/recruitment/recruitment2.png" alt="">
                        <div>高薪岗位</div>
                    </div>
                    <div>
                        <img src="@/assets/img/recruitment/recruitment3.png" alt="">
                        <div>离我最近</div>
                    </div>
                </div>
                <div class="content">
                    <div :class="['search_bar', sticky ? 'sticky' : '']">
                        <div class="search_content">
                            <div @click="showMask('show_sort')" :class="sort_select ? 'select' : ''">
                                <div>{{ down_list[sort_select].text }}</div>
                                <!-- <img src="@/assets/img/recruitment/recruitment3.png" alt=""> -->
                                <div class="iconfont icon-triangle-xia-copy"></div>
                            </div>
                            <div @click="showMask('show_screen')" :class="is_screen? 'select': ''">
                                <div>筛选</div>
                                <!-- <img src="@/assets/img/recruitment/recruitment3.png" alt=""> -->
                                <div class="iconfont icon-triangle-xia-copy"></div>
                            </div>
                            <div>
                                <div class="iconfont icon-sousuo"></div>
                                <form action="javascript:return true" style="width: 100%;line-height: 100%;height: 100%;display: flex;align-items: center;">
                                    <input type="search" placeholder="请输入标题、岗位工种" v-model="keySearchFalse" @blur="onBlur" @input="nullKey" confirm-type="search" @keyup.enter="inputClickSearch" />
                                </form>

                            </div>
                        </div>
                    </div>
                    <div v-if="sticky" class="sticky_height"></div>
                    <div :class="['sort_content', sticky? 'sort_sticky':'']" v-if="show_sort" @touchmove.stop.prevent="nomove">
                        <div v-for="item in down_list" :key="item.index" :style="{
                        color: sort_select == item.value ? '#0abbb9' : '',
                    }" @click="is_click ? '' :  selectDown(item.value)">
                            <div>{{ item.text }}</div>
                            <van-icon v-if="sort_select == item.value" name="success" />
                        </div>
                    </div>
                    <div v-if="list.length">
                        <div class="card" v-for="item in list" :key="item.index" @click="goRecruitmentDetails(item.recruitment_id)">
                            <div class="block">
                                <img v-if="item.factory_img" :src="item.factory_img" alt="">
                                <img v-else src="/img/morencaifeng.b9cabdd9.png" />
                                <div class="right">
                                    <div class="factory">
                                        <div class="name">{{ item.factory_short_name ? item.factory_short_name : item.factory_name }}</div>
                                        <div :class="['tips','color' + item.recruitment_status]">{{ item.recruitment_status_desc }}</div>
                                    </div>
                                    <div class="wage">
                                        <div v-if="item.wage_for_display">{{item.wage_for_display}}</div>
                                        <div v-if="item.salary_for_display">{{item.salary_for_display}}</div>
                                    </div>
                                    <div class="tag" v-if="item.tag_name_list.length">
                                        <div v-for="(tag, tag_key) in item.tag_name_list" :key="tag_key">{{tag}}</div>
                                    </div>
                                    <div class="job">
                                        <div>{{ item.recruitment_job_name }}</div>
                                        <div v-if="item.distance">{{ item.distance_desc }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="border"></div>
                        </div>
                        <div v-if="finished" class="no_more">没有更多了</div>
                    </div>
                    <div v-if="isnodat&&list.length==0">
                        <div class="news_wenan">
                            <div class="news_wenan_img"><img class="news_bg_imh" src="../../assets/img/null_index.png" alt=""></div>
                        </div>
                        <div class="news_wenan_text">
                            <div class="news_wenan_text_on">暂无数据</div>
                        </div>
                    </div>
                </div>

            </van-list>
        </van-pull-refresh>
        <div v-if="show_screen" class="screen_content">
            <div class="screen_top" @touchmove.stop.prevent="nomove">
                <div>筛选</div>
                <div @click="hideMask" class="iconfont icon-guanbi"></div>
            </div>
            <div style="height: calc(100% - 40px); overflow: auto;" @touchmove="nomove1">
                <Screen />
            </div>
        </div>
        <div class="member_info_fixed" v-if="(this.$store.state.memberInfo.mobile || this.$store.state.memberInfo.wechat_qrcode) && is_show">
            <a :href="`tel:${this.$store.state.memberInfo.mobile}`" v-if="this.$store.state.memberInfo.mobile">
                <div style="color:#4C94F6;" class="iconfont icon-dianhua1">
                    <!-- <van-icon name="phone" /> -->
                </div>
            </a>
            <div style="color:#1BD66C" @click="goAddWechatCode" v-if="this.$store.state.memberInfo.wechat_qrcode" class="iconfont icon-weixin-copy
">
                <!-- <van-icon name="wechat" /> -->
            </div>
        </div>
    </div>
</template>
<script>

import config from "@/utils/config.js";
import Screen from '@/view/job/screen'
import { ImagePreview, Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
    components: {
        Screen
    },
    data() {
        return {
            // 分页=======================================
            loading: false,
            lj: null,
            location_value: 1,
            finished: false,
            refreshing: false,
            now_is_success: false,//是否正在请求中
            page: 1, //请求第几页
            total: 0, //总共的数据条数
            // ==========================================
            show_down: false, // 是否展示下拉选项
            show_sort: false, // 是否展示下拉排序
            show_screen: false, // 是否展示筛选
            down_list: [
                { text: "综合排序", value: 0 },
                { text: "工价最高", value: 1 },
                { text: "距离最近", value: 2 },
            ],
            src: `${config.ossImageUrl}`,
            list: [],
            is_click: false, // 是否点击过了排序
            latitude: "",
            longitude: "",
            isnodat: false,
            index: 1,
            is_show: true,
            docmHeight: window.innerHeight, //默认屏幕高度
            showHeight: window.innerHeight, //实时屏幕高度
            iszidingy: this.$store.state.iszidingy,
            sort_select: 0, // 排序方式   0为综合排序   1为工价排序正序    2为工价排序降序
            is_screen: 0, // 是否有筛选条件
            screen: {}, // 筛选的列表  不会改变的  跳转到筛选页面后的使用
            screenValue: {}, // 筛选的值   会改变的  跳转到筛选页面后的使用
            keySearchFalse: "", // 假的关键字，没点击搜索前不生效
            search_key: "", // 搜索的关键字
            sticky: false, // 是否吸顶
            imgList: ['https://ymb01.oss-cn-shenzhen.aliyuncs.com/static/resource/team/job_index_h5.png'],
        }
    },
    watch: {
        show_screen(newVal) {
            // console.log(newVal);
            if (newVal) {
                this.noScroll();
            } else {
                this.canScroll();
            }
        },

        showHeight: function (newValue) {
            if (this.isAndroidOrIOS() == 'android') {
                if (this.docmHeight > newValue) {
                    this.$store.commit("hideTabbar");
                    this.is_show = false
                } else {
                    this.$store.commit('showTabbar');
                    this.is_show = true
                    if (this.show_screen) {
                        this.$store.commit("hideTabbar");
                    }
                }
            }
        },
    },
    activated() {
        if (this.$store.state.refreshList) {
            this.list = [];
            this.page = 1;
            this.getList();
            this.getImgList(); // banner图片
        }
        this.$store.state.active = 0;
        this.$set(this.$store.state, 'active', 0)
        this.$store.commit("showTabbar");
        //this.$store.commit("getWxSDK");
        // this.$store.commit("hideTabbar");

    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.showHeight = window.innerHeight;
            })();
        };
        this.$store.commit("showTabbar");
        this.is_show = true
        this.$store.state.active = 0;

        var content = document.getElementsByClassName('search_bar')[0].offsetTop
        var that = this
        window.onscroll = function () {
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop >= content) {
                that.sticky = true
            } else {
                that.sticky = false
            }
        }
        if (this.getQuery('team_id')) {
            localStorage.setItem('team_id', this.getQuery('team_id'))
        }
        if (this.getQuery('api') || !localStorage.getItem('member_id') || !localStorage.getItem('team_id') || Object.keys(this.$store.state.memberInfo).length == 0) {
            var data = {}
            if(this.getQuery('team_id')){
                data.team_id = localStorage.getItem('team_id')
            }else if (localStorage.getItem('member_id')) {
                data.member_id = localStorage.getItem('member_id')
            } else if (localStorage.getItem('team_id')) {
                data.team_id = localStorage.getItem('team_id')
            }
            this.Request('client/user/member', 'get', data, true, false).then(res => {
                if (res.status == 0) {
                    localStorage.setItem("team_id", res.data.team_id);
                    localStorage.setItem("member_id", res.data.member_id);
                    this.$store.state.memberInfo = res.data;
                    this.getImgList()
                    this.getWxSDK(); // 微信api
                }
            })
        } else {
            this.getImgList(); // banner图片
            this.getWxSDK(); // 微信api
        }

    },
    methods: {
        //控制页面 弹层显示时调用 noScroll()停止页面滚动 ，弹层消失时调用 canScroll()开启页面滚动
        //停止页面滚动
        noScroll() {
            var mo = function (e) {
                e.preventDefault();
            };
            document.body.style.overflow = "hidden";
            document.addEventListener("touchmove", mo, false); // 禁止页面滑动
        },
        //开启页面滚动
        canScroll() {
            var mo = function (e) {
                e.preventDefault();
            };
            document.body.style.overflow = ""; // 出现滚动条
            document.removeEventListener("touchmove", mo, false);
        },
        nomove1(e) {
            e.stopPropagation(); //只执行自己，不执行父组件的事件
        },
        isAndroidOrIOS() {
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isAndroid) {
                return 'android';
            }
            if (isiOS) {
                return 'ios';
            }
            return false;
        },
        nullKey() {
            if (this.keySearchFalse == '' && this.search_key != '') {
                this.search_key = ''
                this.keySearchFalse = ''
                this.getList()
            }
        },
        getQuery(variable) {
            var query = unescape(window.location.href);
            var lengths = query.split("?");
            var vars = lengths[lengths.length - 1].split("&");
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split("=");
                if (pair[0] == variable) {
                    return pair[1];
                }
            }
            return false;
        },
        nomove() { },
        getWxSDK() {
            var url = window.location.href.split("#")[0]; //动态获取链接
            var data = {
                url,
                mtype: "worker_agent_team_mp",
            };
            this.Request('client/api/wechat/jssdk/jsapi_ticket', 'get', data).then(res => {
                res = res.data;
                wx.config({
                    debug: false, // true:是调试模式,调试时候弹窗,会打印出日志
                    appId: res.appId, // 微信appid
                    timestamp: res.timestamp, // 时间戳
                    nonceStr: res.nonceStr, // 随机字符串
                    signature: res.signature, // 签名
                    jsApiList: [
                        "checkJsApi",
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage',
                        'onMenuShareQQ',
                        'onMenuShareWeibo',
                        'onMenuShareQZone',
                        "getLocation"],
                });
                var that = this
                wx.ready(() => {
                    var link
                    if (sessionStorage.getItem('introducer_id')) {
                        link = process.env.VUE_APP_BASE_URL + `recruitmentList?member_id=${this.$store.state.memberInfo.member_id}&introducer_id=` + sessionStorage.getItem('introducer_id')
                    } else if (this.$store.state.memberInfo.jobseeker_id) {
                        link = process.env.VUE_APP_BASE_URL + `recruitmentList?member_id=${this.$store.state.memberInfo.member_id}&jobseeker_id=${this.$store.state.memberInfo.jobseeker_id}&source=` + sessionStorage.getItem('source')
                    } else {
                        link = process.env.VUE_APP_BASE_URL + `recruitmentList?team_id=${this.$store.state.memberInfo.team_id}&source=` + sessionStorage.getItem('source')
                    }
                    var wxData = {
                        //执行分享
                        title: this.$store.state.memberInfo.team_name, // 分享标题
                        desc: '这里有个好厂在招人，快来看看吧。', // 分享描述
                        link, // 分享链接
                        imgUrl: config + 'job_index_h5.png', // 分享图标
                        type: 'link', // 分享类型,music、video或link，不填默认为link
                        success: function () {
                        },
                        cancel: function () {
                        }
                    }
                    wx.updateAppMessageShareData(wxData) // 微信好友
                    wx.updateTimelineShareData(wxData) // 朋友圈
                    wx.onMenuShareTimeline(wxData) // 朋友圈（旧）
                    wx.onMenuShareAppMessage(wxData) // 微信好友（旧）
                    wx.onMenuShareQQ(wxData) // QQ
                    wx.onMenuShareWeibo(wxData) // 腾讯微博
                    wx.onMenuShareQZone(wxData) // QQ空间
                    this.location_value = 1;
                    clearTimeout(this.lj)
                    this.lj = setTimeout(() => {
                        if (this.location_value == 1) {
                            this.location_value = 2;
                            that.is_click = false
                            that.show_down = false; // 关闭遮罩层
                            this.getList();
                        }
                    }, 3000);
                    wx.getLocation({
                        success: function (res) {
                            if (that.location_value == 1) {
                                that.location_value = 2;
                                that.latitude = res.latitude;
                                that.longitude = res.longitude;
                                that.$store.state.latitude = res.latitude
                                that.$store.state.longitude = res.longitude
                                that.show_down = false; // 关闭遮罩层
                                // that.sort_select = 2;
                                that.is_click = false
                                that.getList();
                            }

                        },
                        cancel: function () {
                            if (that.location_value == 1) {
                                that.location_value = 2;
                                that.is_click = false
                                that.show_down = false; // 关闭遮罩层
                                that.getList();
                                // Toast("您未开启地理位置授权");
                            }

                        },
                        fail: function () {
                            if (that.location_value == 1) {
                                that.location_value = 2;
                                that.is_click = false
                                that.show_down = false; // 关闭遮罩层
                                that.getList();
                                // Toast("请打开授权定位");
                            }

                        },
                    });
                })
                wx.checkJsApi({
                    jsApiList: [
                        "checkJsApi",
                        'updateAppMessageShareData',
                        'updateTimelineShareData',
                        'onMenuShareTimeline',
                        'onMenuShareAppMessage',
                        'onMenuShareQQ',
                        'onMenuShareWeibo',
                        'onMenuShareQZone',
                        "getLocation"
                    ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                    success: function () {
                        //在这里提示点击右上角分享
                    },
                });
            })
        },
        getImgList() {
            var data = {
                member_id: localStorage.getItem("member_id"),
            };
            this.Request('banner/list', 'get', data).then(res => {
                if (res.status == 0) {
                    var imgList = [];
                    if (res.data.list.length) {
                        res.data.list.filter((v) => {
                            imgList.push(v.banner_full_img_url);
                        });
                    } else {
                        imgList = [this.src + 'job_index_h5.png']
                    }
                    this.imgList = imgList;
                    this.$set(this, 'imgList', this.imgList)
                }
            })
        },
        // 解决ios端的键盘弹起来后会把页面顶上去，导致点击其他元素（尤其是弹出框）的效果失效
        onBlur() {
            setTimeout(function () {
                let scrollHeight =
                    document.documentElement.scrollTop ||
                    document.body.scrollTop ||
                    0;
                window.scrollTo(0, Math.max(scrollHeight - 1, 0));
            }, 100);
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.page = 1;
            this.getImgList(); // banner图片
            this.getList();
        },
        // 跳转到招工详情
        goRecruitmentDetails(id) {
            // 这里是登录
            this.$router.push({
                path: "/recruitmentDetails",
                query: { rid: id, introducer_id: sessionStorage.getItem('introducer_id') || -1 },
            });
        },
        // 调接口，获取列表数据
        getList(page = 1) {
            if (localStorage.getItem('member_id')) {
                if (page == 1) {
                    this.list = [];
                    this.finished = false;
                    // 重新加载数据
                    // 将 loading 设置为 true，表示处于加载状态
                    this.loading = true;
                    this.page = 1
                }
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                this.$store.state.refreshList = false;
                var data = {
                    member_id: localStorage.getItem("member_id"),
                    limits: 20,
                    page: page,
                };
                if (sessionStorage.getItem('introducer_id') > 0 || localStorage.getItem('jobseeker_id') > 0) {
                    data.introduce_id = sessionStorage.getItem('introducer_id') || localStorage.getItem('jobseeker_id')
                }
                // 关键字
                if (this.search_key) data.search_key = this.search_key;
                // 排序方式
                if (this.sort_select == 1) {
                    data.order_type = "wage_max"; // 排序方式，薪资排序
                    data.order_direction = "desc";
                } else if (this.sort_select == 2) {
                    data.order_type = "distance"; // 排序方式，距离排序
                    data.order_direction = "desc";

                }
                data.longitude = this.longitude;
                data.latitude = this.latitude;
                // 筛选
                if (Object.keys(this.$store.state.screenValue).length) {
                    this.is_screen = false;
                    // 状态
                    if (this.$store.state.screenValue.filter_job_status[0] != -1) {
                        data.filter_job_status = JSON.stringify(
                            this.$store.state.screenValue.filter_job_status
                        );
                        this.is_screen = true;
                    }
                    // 标签
                    var array = [];
                    var arraytts = [];
                    for (var j = 0; j <= 6; j++) {
                        if (j == this.$store.state.iszidingy) {
                            this.$store.state.screenValue[
                                "filter_tag_list[" + j + "]"
                            ].filter((v) => {
                                if (v != -1) {
                                    arraytts.push(v);
                                }
                            });

                        } else {
                            this.$store.state.screenValue[
                                "filter_tag_list[" + j + "]"
                            ].filter((v) => {
                                if (v != -1) {
                                    array.push(v);
                                }
                            });
                        }

                    }
                    if (array.length) {
                        data.filter_tag_list = JSON.stringify(array);
                        this.is_screen = true;
                    }
                    if (arraytts.length) {
                        data.filter_customize_tag_list = JSON.stringify(arraytts);
                        this.is_screen = true;
                    }
                } else {
                    this.is_screen = false;
                }
                this.page++;
                if (sessionStorage.getItem('is_getAllList') || this.getQuery('team_id')) {
                    data.all_data = 1
                }
                this.isnodat = false
                this.Request('jobseeker/apply/job', 'get', data, true, false).then(res => {
                    if (res.status == 0) {
                        res.data.member_recruitment_list.filter((v, i) => {
                            if (v.tag_name_list.toString().length > 12) {
                                var num = 12
                                var tag_name_list = []
                                v.tag_name_list.filter((val) => {
                                    num -= val.length
                                    if (num > 0) {
                                        tag_name_list.push(val)
                                    }
                                })
                                tag_name_list.push('...')
                                res.data.member_recruitment_list[i].tag_name_list = tag_name_list
                            }

                        })
                        if (page == 1) {
                            this.list = res.data.member_recruitment_list
                        } else {
                            this.list = this.list.concat(
                                res.data.member_recruitment_list
                            );
                        }
                        this.isnodat = true
                        this.loading = false;
                        if (res.data.member_recruitment_list.length < 20) {
                            this.finished = true;
                        }
                        // if (this.list.length >= res.data.count) {
                        //     this.finished = true;
                        // }
                    }
                })
            } else {
                this.getList()
            }
        },
        // 查看全部图片
        lookImg(key) {
            ImagePreview({
                images: this.imgList,
                startPosition: key,
            });
        },
        onLoad(page) {
            this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            if (page != 1) {
                this.getList(page);
            }
        },
        // 选择了排序方式
        selectDown(value) {
            this.is_click = true
            var that = this;
            // 清空列表数据
            this.finished = false;
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            if (value == 2) {
                if (!!this.latitude == false) {
                    Toast.loading({
                        message: '加载中...',
                        forbidClick: true,
                    });
                    this.location_value = 1;
                    clearTimeout(this.lj)
                    this.lj = setTimeout(() => {
                        if (this.location_value == 1) {
                            this.location_value = 2;
                            that.is_click = false
                            that.show_sort = false; // 关闭遮罩层
                            that.show_screen = false; // 关闭遮罩层
                            this.getList();
                        }
                    }, 3000);
                    wx.getLocation({
                        success: function (res) {
                            if (that.location_value == 1) {
                                that.location_value = 2;
                                that.latitude = res.latitude;
                                that.longitude = res.longitude;
                                that.$store.state.latitude = res.latitude
                                that.$store.state.longitude = res.longitude
                                that.show_sort = false; // 关闭遮罩层
                                that.show_screen = false; // 关闭遮罩层
                                that.sort_select = 2;
                                that.is_click = false
                                that.getList();
                            }
                        },
                        cancel: function () {
                            if (that.location_value == 1) {
                                that.location_value = 2;
                                that.is_click = false
                                that.show_sort = false; // 关闭遮罩层
                                that.show_screen = false; // 关闭遮罩层
                                Toast("您未开启地理位置授权");
                                that.getList();

                            }

                        },
                        fail: function () {
                            if (that.location_value == 1) {
                                that.location_value = 2;
                                that.is_click = false
                                that.show_sort = false; // 关闭遮罩层
                                that.show_screen = false; // 关闭遮罩层
                                Toast("请打开授权定位");
                                that.getList();

                            }

                        },
                    });
                } else {
                    that.show_sort = false; // 关闭遮罩层
                    that.show_screen = false; // 关闭遮罩层
                    this.sort_select = 2;
                    this.is_click = false
                    this.getList();
                }
            } else {
                that.is_click = false
                that.show_sort = false; // 关闭遮罩层
                that.show_screen = false; // 关闭遮罩层
                that.sort_select = value;
                that.getList();
            }
        },
        showMask(value) {
            this.show_sort = false // 是否展示下拉排序
            this.show_screen = false // 是否展示筛选
            // if (value == 'show_screen') {
            //     this.$store.commit('hideTabbar');
            // }
            this[value] = true
        },
        hideMask() {
            this.show_sort = false // 是否展示下拉排序
            this.show_screen = false // 是否展示筛选
            this.$store.commit("showTabbar");
        },
        // 跳转到招工信息搜索页面
        goRecruitmentSearch() {
            this.$router.push("/recruitmentSearch");
        },
        // 跳转到招工信息筛选页面
        goRecruitmentScreen() {
            this.$router.push("/recruitmentScreen");
        },
        // 点击了搜索的搜索框
        inputClickSearch() {
            this.search_key = this.keySearchFalse;
            this.list = [];
            this.getList();
        },
        // 添加微信
        goAddWechatCode() {
            this.$router.push({ path: '/addWechatCode', query: { img: '', name: this.$store.state.memberInfo.operator_name || '', phone: this.$store.state.memberInfo.mobile || '' } });
        },
    },
}
</script>
<style scoped>
.screen_top {
    background-color: #fff;
    height: 90px;
    border-radius: 20px 20px 0 0;
    line-height: 90px;
    text-align: center;
}
.screen_top > div {
    font-size: 32px;
}
.screen_top > div:last-of-type {
    position: absolute;
    right: 30px;
    top: 0;
}

.van-pull-refresh >>> .van-pull-refresh__track {
    min-height: 90vh;
}

.list {
    min-height: 100vh;
    background-color: #fff;
}
.input-placeholder {
    font-size: 22px;
    color: #999;
}
.custom-indicator {
    width: 8px;
    height: 4px;
    position: absolute;
    bottom: 30px;
    text-align: center;
}
.top {
    padding-top: 30px;
}
.top .my-swipe {
    margin: 0 30px;
    height: 280px;
    border-radius: 10px;
}
.top .my-swipe img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.top .Tips {
    display: flex;
    justify-content: space-between;
    color: #999;
    margin: 30px 30px 0;
    height: 50px;
    background-color: #f9f9f9;
    border-radius: 4px;
    opacity: 0.8;
    align-items: center;
    padding: 0 24px;
}
.top .Tips span {
    font-size: 20px;
    margin-right: 8px;
    color: #0abbb9;
}
.top .Tips div {
    font-size: 20px;
}

.middle {
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 0;
    display: none;
}
.middle img {
    width: 44px;
    height: 44px;
}
.middle > div {
    width: 154px;
    height: 86px;
    background-color: #f2fefb;
    padding: 22px 30px;
    border-radius: 4px;
    font-size: 24px;
}
.middle > div:nth-of-type(2) {
    background-color: #f4f7ff;
}
.middle > div:last-of-type {
    background-color: #fff9f1;
}

/* .content{position: relative;} */
.search_bar {
    /* display: flex; */
    color: #999;
    padding: 30px 30px 0;
    position: relative;
}

.sticky {
    position: fixed;
    top: 0;
    width: calc(100% - 60px);
    background-color: #fff;
    padding: 30px;
}
.sticky_height {
    height: 90px;
}
.search_bar > .search_content {
    display: flex;
    width: 100%;
}
.search_bar > .search_content > div {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: #f8f8f8;
    /* line-height: 60px; */
    margin-right: 16px;
    padding: 0 16px;
}
.search_bar > .search_content > div:first-of-type {
    width: 148px;
}
.search_bar > .search_content > div:nth-of-type(2) {
    width: 88px;
}
.search_bar > .search_content > div:last-of-type {
    margin-right: 0;
    width: calc(100% - 232px);
}
.search_bar input {
    border: 0;
    background-color: #f8f8f8;
    width: 100%;
    margin-left: 10px;
    /* -ms-line-height: 40px; */
    /* line-height: 40px; */
}
.search_bar > .search_content > div.select {
    color: #0abbb9;
}
.search_bar > .search_content > div > div,
.search_bar input {
    font-size: 24px;
}
.search_bar > .search_content .select {
    background-color: #e9f9fe;
    color: #0abbb9;
}

.sort_content {
    width: 230px;
    padding: 0 30px;
    background: #fff;
    border-radius: 10px;
    position: absolute;
    z-index: 2;
    left: 30px;
    margin-top: 20px;
    /* top: 30px; */
}
.sort_content > div {
    padding: 30px 0;
    border-bottom: 1px solid #f8f8f8;
    display: flex;
    justify-content: space-between;
}
.sort_content > div:last-of-type {
    border: 0;
}
.sort_content .select {
    color: #0abbb9;
}
.sort_sticky {
    position: fixed;
    top: 90px;
}

.card .block {
    margin: 0 30px;
    display: flex;
    padding: 30px 0;
}
.card .block > img {
    width: 230px;
    height: 176px;
    border-radius: 4px;
    margin-right: 20px;
    object-fit: cover;
}
.card .block .right {
    width: calc(100% - 260px);
    height: 176px;
    /* align-items: center; */
    /* display: inline-grid; */
}
.card .block .right .factory {
    display: flex;
    align-items: center;
    font-size: 32px;
    height: 40px;
    color: #1a1a1a;
    font-weight: 700;
    margin-bottom: 12px;
}
.card .block .right .factory .name {
    font-size: 32px;
    max-width: 82%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.card .block .right .factory .tips {
    font-size: 18px;
    font-weight: 400;
    padding: 0 10px;
    margin-left: 16px;
    background-color: #eeffff;
    color: #0abbb9;
    height: 28px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    /* line-height: 28px; */
    /* margin-top: 10px; */
}
.card .block .right .factory .color50 {
    color: #ec1203;
    background-color: #faeae9;
}
.card .block .right .factory .color40 {
    color: #ff6a00;
    background-color: #fcf4ee;
}
.card .block .right .factory .color20 {
    color: #999999;
    background-color: #f3f3f3;
}

.card .block .right .wage {
    font-weight: 700;
    color: #0abbb9;
    display: flex;
    height: 30px;
    white-space: nowrap;
    margin-bottom: 18px;
}
.card .block .right .wage > div:first-of-type {
    margin-right: 20px;
}
.card .block .right .tag {
    display: flex;
    margin-bottom: 10px;
}
.card .block .right .tag > div {
    height: 40px;
    /* line-height: 32px; */
    display: flex;
    align-items: center;
    color: #999;
    white-space: nowrap;
    background-color: #f8f8f8;
    font-size: 22px;
    padding: 0 9px;
    margin-right: 8px;
}

.card .block .right .job {
    display: flex;
    height: 30px;
    align-items: center;
}
.card .block .right .job > div {
    font-size: 22px;
    color: #999;
    margin-right: 10px;
}
.card .border {
    background-color: #f8f8f8;
    height: 16px;
}
.icon-guanbi {
    font-size: 20px !important;
}
.screen_content {
    position: fixed;
    width: 100%;
    height: 80vh;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    bottom: 0;
    z-index: 2;
}
.member_info_fixed {
    position: fixed;
    right: 30px;
    bottom: 200px;
}
.member_info_fixed div {
    background: #fff;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
    font-size: 60px;
    padding: 5px;
}
.icon-triangle-xia-copy {
    font-size: 10px !important;
    margin-left: 6px;
    margin-top: 8px;
    color: #c6c6c6;
}
.select .icon-triangle-xia-copy {
    color: #0abbb9;
}
.icon-sousuo {
    font-size: 22px !important;
}
.no_more {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
}
</style>
